<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h1>Solusi Terbaik Keseharian Anda</h1>
          <h2>Kami Melayani anda dengan sepenuh hati</h2>
          <div
            class="d-flex justify-content-center justify-content-lg-start gap-2"
          >
            <router-link to="/gabungmitra" class="btn-get-started scrollto"
              >Gabung Mitra Yohom
            </router-link>
            <a
              href="https://wa.me/6282228502424?&text=Hai Yohom, Saya mau melakukan pemesanan jasa. Bisakah anda membantu?"
              target="_blank"
              class="btn-via-wa"
              ><i class="bi bi-whatsapp"></i>
              <span>&nbsp;Pesan Via Whatsapp</span>
            </a>
          </div>
        </div>
        <div
          class="col-lg-6 order-1 order-lg-2 hero-img"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <img
            src="assets/img/hero-phone.png"
            class="img-fluid animated"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style>
</style>