<template>
  <footer id="footer">
    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Unduh Yohom Sekarang</h4>

            <img width="200px" src="assets/img/download-ps.png" alt="" />
            <br />
            <br />
            <b>Aplikasi Android</b>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>
              <img width="130px" src="assets/img/logos/logo.png" alt="" />
            </h3>
            <p>
              Tumpang Krasak <br />
              Kudus, Bae 59327<br />
              Indonesia <br /><br />
              <strong>Phone:</strong> +62 8189 9067 554<br />
              <strong>Email:</strong> akunyohom@gmail.com<br />
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Tautan Terkait</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="/">Beranda</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Tentang Kami</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Terms of service</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Privacy policy</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Menu</h4>
            <ul>
              <!-- <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Produk Kami</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Testimoni</a>
              </li> -->
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Blog</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Gabung Mitra</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">FAQs</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Sosial Media Yohom</h4>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="youtube"><i class="bx bxl-youtube"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        <strong
          ><span
            >PT Yohom Indonesia Solution &copy; Copyright 2022
          </span></strong
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>