<template>
  <main id="main">
    <section id="cliens" class="contact">
      <div class="container">
        <div class="section-title" style="padding-bottom: 0px">
          <h2>Daftar Pesanan</h2>
        </div>
        <div class="row">
          <!-- Form -->
          <div class="col-12">
            <h5>Silahkan Pilih Layanan</h5>
            <!-- Kategori Pekerjaan -->
            <div
              v-if="pekerjaans.length > 0"
              class="accordion"
              id="accordionExample"
            >
              <div
                v-for="(pekerjaan, index) in pekerjaans"
                :key="index"
                class="accordion-item"
              >
                <h2 class="accordion-header" :id="'heading' + index">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + index"
                    aria-expanded="false"
                    :aria-controls="'collapse' + index"
                  >
                    {{ pekerjaan.kategori_nama }}
                  </button>
                </h2>
                <div
                  :id="'collapse' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'heading' + index"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="row">
                      <!-- Pekerjaan -->
                      <div class="col-12">
                        <div
                          v-if="pekerjaan.pekerjaan.length > 0"
                          class="accordion"
                          id="accordionPekerjaan"
                        >
                          <div
                            v-for="(
                              pekerjaanList, indexPekerjaan
                            ) in pekerjaan.pekerjaan"
                            :key="indexPekerjaan"
                            class="accordion-item"
                          >
                            <h2
                              class="accordion-header"
                              :id="'headingP' + indexPekerjaan"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#collapseP' + indexPekerjaan"
                                aria-expanded="false"
                                :aria-controls="'collapseP' + indexPekerjaan"
                              >
                                <div class="flex flex-row gap-2">
                                  <img
                                    :src="pekerjaanList.gbr_pekerjaan"
                                    style="width: 30px; height: 30px"
                                  />
                                  <span
                                    style="margin-left: 10px"
                                    class="fw-semibold"
                                    >{{ pekerjaanList.nama_pekerjaan }}</span
                                  >
                                </div>
                              </button>
                            </h2>
                            <div
                              :id="'collapseP' + indexPekerjaan"
                              class="accordion-collapse collapse"
                              :aria-labelledby="'headingP' + indexPekerjaan"
                              data-bs-parent="#accordionPekerjaan"
                            >
                              <div class="accordion-body">
                                <div class="row">
                                  <div class="col-12">
                                    <!-- Keterangan -->
                                    <div
                                      v-for="(
                                        keterangan, indexKet
                                      ) in pekerjaanList.keterangan"
                                      :key="indexKet"
                                    >
                                      <div
                                        @click="directWA(keterangan)"
                                        class="card mb-3 clickable-div"
                                      >
                                        <div class="card-body row">
                                          <div class="col-10">
                                            <p
                                              class="mb-0 fw-bold"
                                              style="font-size: 12px"
                                            >
                                              {{ keterangan.ket_pekerjaan }}
                                            </p>
                                            <p
                                              style="margin: 0; font-size: 10px"
                                              class="text-muted"
                                            >
                                              Rp.
                                              {{
                                                formatNumber(keterangan.harga)
                                              }}
                                            </p>
                                          </div>
                                          <div class="col-2 text-end">
                                            <i class="bi bi-whatsapp" style="color:green;font-size: 20px;"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
  
<script>
export default {
  name: "SimulasiPesanan",
  data() {
    return {
      pekerjaans: [],
      pekerjaanSelected: [],
      totalHarga: 0,
      biayaSurvey: 25000,
      config: {
        headers: {
          api_key: "b2e77a7c2d6bb1630b5df98624bc885a",
        },
      },
    };
  },
  mounted() {
    this.getPekerjaan();
  },
  methods: {
    async getPekerjaan() {
      const form = new FormData();
      form.append("wilayah_id", "1");

      await fetch("https://api.yohom.co.id/pelanggan/pekerjaan", {
        method: "POST",
        body: form,
        headers: {
          api_key: "b2e77a7c2d6bb1630b5df98624bc885a",
        },
      })
        .then((response) => response.json()) // Parsing respons JSON
        .then((data) => {
          console.log(data);
          this.pekerjaans = data.res; // Mengupdate data sesuai dengan respons
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setPekerjaan(pekerjaan) {
      this.pekerjaanSelected.push(pekerjaan);
      this.getTotalHarga();
    },
    deletePekerjaan(indexPekerjaan) {
      this.pekerjaanSelected.splice(indexPekerjaan, 1);
      this.getTotalHarga();
    },
    getTotalHarga() {
      let total = 0;
      this.pekerjaanSelected.forEach((pekerjaan) => {
        total += pekerjaan.harga;
      });
      this.totalHarga = total + this.biayaSurvey;
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    directWA(pekerjaan) {
      window.open(
        "https://wa.me/6282228502424?&text=Hai Yohom, Saya mau melakukan pemesanan jasa :" +
          pekerjaan.ket_pekerjaan +
          "Rp." +
          pekerjaan.harga +
          ", Bisakah anda membantu?",
        "_blank"
      );
    },
  },
};
</script>